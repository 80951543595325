<template>
	<div class="container">
		<div class="row	px-3">
			<div class="col col-sm-10 col-md-8 col-lg-6 col-xl-4 col-xxl-4 mx-auto py-3 pt-4 px-4 pb-10 mx-4 bg-white border-bottom border-brd-primary mb-4 rounded-1 shadow position-relative">
				<div v-if="!this.is_processed" class="page-header mb-3">
					<h1 class="page-title mb-2" v-text="this.title" />
					<label v-if="!this.use_log_pass && this.accounts_count > 0" class="text-dark-gray">Выберите сохраненный аккаунт:</label>
				</div>
				<ul v-if="!this.is_processed && !this.use_log_pass && this.accounts_count > 0" class="list-group list-group-flush my-2 mx-0 overflow-auto w-100 pe-2" style="max-height: 40vh;">
					<a v-for="(account, index) in accounts" :key="index"
					   href="javascript:{}"
					   class="list-group-item p-0 pt-3 pe-2">
						<div class="row mx-0 mt-1 mb-4">
							<div class="col-3 ps-0"
								 @click="this.onAuth(account.id, account.session_id)">
								<img v-if="account.avatar" :src="account.avatar" alt="Аватар" width="64" height="64" class="d-block rounded-circle" />
								<img v-else :src="require('@/assets/img/user_avatar.png')" alt="Аватар" width="64" height="64" class="d-block rounded-circle" />
							</div>
							<div class="col-9 pe-0 d-flex align-items-center position-relative">
								<div class="d-flex flex-column my-auto w-100 align-content-center overflow-hidden"
									 @click="this.onAuth(account.id, account.session_id)">
									<h3 class="text-black fs-3 vw-60 text-nowrap-mask my-auto me-0">{{ account.name }}</h3>
									<span class="text-primary">{{ this.formatPhoneNumber(account.phone) }}</span>
									<small class="position-absolute text-font-light fs-5 ms-auto"
										   style="bottom:-12px;right:-12px;">{{ this.timestampToDate(account.timestamp) }}</small>
								</div>
								<button type="button"
										class="position-absolute ms-auto ms-auto btn-close"
										style="top:-12px;right:-12px;"
										@click.self="this.removeAccount(account.id)"></button>
							</div>
						</div>
					</a>
				</ul>
				<LoginForm :use_phone_mask="!this.$route.query.AZ || this.$route.query.AZ !== '1'" v-else :errorsList="this.errors" @currentState="(state) => this.is_processed = state.proccessed" />
				<div v-if="!this.is_processed && !this.use_log_pass && this.accounts_count > 0" class="d-flex flex-column my-4">
					<a href="javascript:{}" @click="this.use_log_pass = 1" class="d-inline-flex align-items-center ms-0 me-auto">
						<span aria-label="Вернуться назад"
							  class="btn btn-outline-secondary bg-light-gray me-10 border border-brd-primary rounded-3"
							  style="padding: 0.75rem 0.95rem;">
							<img :src="require('@/assets/icons/undo.svg')" alt="Назад" width="18" height="18">
						</span>
						<span class="text-font-light">Войти по номеру телефона</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import CommonService from "../services/CommonService";
	import LoginForm from "@/components/forms/LoginForm";
	import Session from "@/services/Session";
	import moment from 'moment';
	import api from "@/api";

    export default {
        name: "Login",
		components: {
			LoginForm
		},
		props: {

        },
		data() {
            return {
                title: "Авторизация",
				use_log_pass: true,
				is_processed: false,
				errors: [],
            };
        },
        methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(heedle, haystack) {
				return CommonService.inArray(heedle, haystack);
			},
			formatPhoneNumber(phone) {
				return CommonService.formatPhoneNumber(phone);
			},
			timestampToDate(timestamp) {
				return moment.unix(timestamp/1000).format("DD.MM.Y в H:mm");
			},
			getTimestamp(interval, is_unix) {
				return CommonService.getTimestamp(interval, is_unix)
			},
			getAccounts() {
				let accounts = this.$store.getters.userAccounts;
				if (!this.isEmpty(accounts)) { // Check lifetime session of expires
					let datetime = CommonService.dateAdd(new Date, -180, 'minute', true, false);
					accounts = accounts.filter(item => item.timestamp >= datetime);
				}

				return accounts;
			},
			removeAccount(account_id, confirmed) {

				CommonService.log('info', 'removeAccount()', {account_id, confirmed});

				if (typeof confirmed == "undefined")
					confirmed = false;

				if (confirmed && typeof account_id !== "undefined") {
					this.$store.commit('removeAccount', account_id);
				} else {
					if (typeof account_id !== "undefined" && confirm('Удалить аккаунт из истории?')) {
						this.$store.commit('removeAccount', account_id);
					}
				}
			},
			onAuth(user_id, session_id) {

				let _this = this;
				this.errors = [];
				this.$store.commit('setSessionId', session_id);

				Session.set('token', session_id, true);

				api.post('/user/login', {
					user_id: user_id,
					restore: true,
				}, {
					headers: {
						'Access-Token': session_id, // Запрос без токена (авторизация)
					}
				}).then((response) => {

					CommonService.log('debug', 'onAuth()::axios', {response});

					if (response.status == 200 && response.data.success) {
						// Читаем выданный сервером токен и запоминаем
						if (response.data.user_id && response.headers['access-token']) {

							let session_id = response.headers['access-token'];
							_this.$store.commit('setSessionId', session_id);
							Session.set('token', session_id, true);

							_this.$store.commit('setOptions', response.data.options);

							if (response.data.options.direct_auth) {
								setTimeout(LoginForm.methods.afterLogin(this, true), 3000);
							} else {
								setTimeout(LoginForm.methods.afterLogin(this), 3000);
							}
						}
					} else {
						//this.errors = response.data.errors;
						//_this.removeAccount(user_id, true);
						_this.errors.push('Произошла ошибка! Данные авторизации уже устарели.');
						_this.use_log_pass = true;
						setTimeout(() => {
							_this.errors = [];
						}, 3000);
					}
				}).catch(function (error) {

					CommonService.log('error', 'onAuth()::axios', error);

					//_this.removeAccount(user_id, true);
					_this.errors.push('Произошла ошибка авторизации.');
					_this.use_log_pass = true;
					setTimeout(() => {
						_this.errors = [];
					}, 3000);
				});
			},
        },
        mounted() {
            CommonService.setPageTitle(this.title);
			Session.clear(true);
			Session.clear();
			this.$store.commit('setSessionId', null);
			this.$store.commit('setUserInfo', {});

			if (this.accounts_count > 0)
				this.use_log_pass = false;

			console.debug('getAccounts()', {
				count: this.accounts_count,
				accounts: this.getAccounts()
			});
        },
		computed: {
			user_is_auth() {

				if (!this.isEmpty(this.$store.getters.userInfo.id))
					return true;

				return false;
			},
			accounts() {
				let accounts = this.getAccounts().reverse();
				let ids = CommonService.uniqueArray(accounts, 'id');

				if (accounts.length) {
					let count = 0;
					return accounts.filter(account => {
						if (CommonService.inArray(account.id, ids)) {

							var index = ids.indexOf(account.id);
							if (index !== -1)
								ids.splice(index, 1);

							if (count <= 5) {
								if (account.timestamp >= CommonService.getTimestamp('-24 hours')) {
									count++;
									return true;
								}
							}
						}

						return false;
					});
				} else {
					return [];
				}
			},
			accounts_count() {
				return this.accounts.length;
			},
		},
    };
</script>